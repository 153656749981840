<template>
  <div class="seedetail">
    <van-nav-bar
      title="提现详情"
      left-arrow
      @click-left="onClickLeft"
      fixed
    />
    <van-notice-bar left-icon="volume-o" text="收益会随着订单取消或售后产生的退款而变化，仅供参考。" />
    <div v-if="list.length > 0">
      <div class="list" v-for="(item, index) in list" :key="index">
        <van-cell center>
          <template #icon>
            <div class="user_img">
              <van-image
                round
                lazy-load
                width="20"
                height="20"
                style="display: block;"
                :src="item.avatar"
              >
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>

            </div>
          </template>
          <template #title>
            <div class="van-ellipsis">
              {{item.nickname}}
            </div>
          </template>
          <template #right-icon>
            <div class="order_sn">
              {{item.ordersn}}
            </div>
          </template>
        </van-cell>
        <van-card
          v-for="(val, key) in item.goods"
          :key="key"
          :desc="val.optiontitle"
          :thumb="val.thumb"
          :title="val.goodstitle"
          :num="val.total"
          :price="val.price"
          lazy-load
        />
        <van-cell center class="order_price">
          <template #icon>
            <div class="status_str">{{item.statusStr}}</div>
          </template>
          <template #default>
            实付：&yen;{{item.price}}<span v-if="parseFloat(item.dispatchprice) > 0">(含运费 &yen;{{item.dispatchprice}})</span>
          </template>
        </van-cell>
        <van-cell center class="order_price">
          <template #icon>
            <div class="status_str">{{item.statusApStr}}</div>
          </template>
          <template #default>
            <van-tag plain type="danger">Y：&yen;{{item.income}}</van-tag>
          </template>
        </van-cell>
      </div>
      <div class="list_bottom">
        <van-cell title="没有更过了" :border="false" title-class="title_class"></van-cell>
      </div>
    </div>
    <div v-if="nodata">
      <van-empty description="暂无订单" />
    </div>
    <!-- 加载中提示 -->
    <div id="loading" v-show="loadshow">
      <van-loading size="24px" type="spinner" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script>
export default {
  name: "SeeDetail",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      list: [], // 列表
      id: 0, // 提现id
      shareid: 0,
      merchid: 0,
      nodata: false,
      loadshow: true
    }
  },
  mounted() {
    this.id = this.$route.query.id;

    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.$parent.getmerchname(this.merchid, "提现详情");
    this.init();
  },
  methods: {
    init() {
      console.log("sss");
      // 获取订单数据
      this.getTeamOrder()
    },
    // 返回上一页
    onClickLeft(){
      this.$router.go(-1);
    },
    // 获取团队订单
    getTeamOrder() {
      let _that = null;
      _that = this;
      _that.loadshow = true;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/commission/log_orders",
          _that.$qs.stringify({
            id: _that.id
          })
        )
        .then(response => {
          console.log(response);
          _that.nodata = false;
          _that.loadshow = false; // 加载框隐藏
          if (response.data.code == 100000) {
            if (response.data.data.length > 0) {
              _that.list = response.data.data;
            }
          }else{
            _that.nodata = true;
          }
        })
        .catch(error => {
          console.log(error);
        })
    }
  }
}
</script>
<style lang="less">
.seedetail{
  position: relative;
  z-index: 1;
  background-color: #ededed;
  width: 100%;
  min-height: 100%;
  padding-top: 50px;
  overflow: hidden;
  .van-nav-bar{
    .van-icon{
      color: #323233;
    }
  }
  .list{
    background-color: #fff;
    overflow: hidden;
    margin: 10px;
    border-radius: 10px;
    text-align: left;
    padding-top: 10px;
    .van-cell {
      padding: 2px 16px;
      .van-cell__title {
        flex: 1;
        overflow: hidden;
      }
      .user_img {
        margin-right: 5px;
      }
    }
    .van-card{
      background-color: #fff;
      padding: 10px;
      .van-card__thumb{
        img{
          border-radius: 4px;
        }
      }
    }
    .van-card:not(:first-child){
      margin-top: 0;
    }
    .order_price {
      padding: 10px 16px;
      .van-cell__value {
        flex: 1;
        text-align: right;
      }
      .status_str {
        color: #EE0A24;
      }
    }
  }

  .list_bottom {
    .van-cell {
      background: #ededed;
      margin-bottom: 10px;
    }
    .title_class {
      flex: 1;
      text-align: center;
      color: #999;
    }
  }
  // 加载中
  #loading {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    .van-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -50px 0 0 -50px;
      padding: 20px;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      .van-loading__text{
        color: #fff;
      }
    }
  }
}
</style>